
.footer {
    @include tr;
    @include fc;
    flex-direction: column;
    text-align: center;
    fill: inherit;
    color: inherit;
    margin: 6px;

    & address {
        @include fc;
        fill: inherit;
        color: inherit;

        & a {
            @include fc;
            margin: 6px;
            fill: inherit;
            color: inherit;
            cursor: pointer;

            & p {
                margin: inherit;
                color: inherit;
                cursor: inherit;
            }

            & svg {
                @include tr;
                fill: inherit;
                color: inherit;
                width: 14px;
                height: 14px;
                cursor: inherit;
            }
        }
    }
}
.header {
    @include tr;

    h1 {
        text-align: center;
    }

    & nav {
        @include fc;
    }
}

.settings {
    @include fc;
}
